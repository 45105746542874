import React, { useState } from "react";
import { useDepartmentsApi } from "@/api/departments";
import { IoChevronForwardOutline, IoTimeOutline } from "react-icons/io5";
import { MERCHANDISED_DEPARTMENTS } from "@/data/query-keys";
import { useQuery } from "@tanstack/react-query";
import { IoArrowBack } from "react-icons/io5";

// Import statements
import {
	FaBabyCarriage,
	FaBook,
	FaCameraRetro,
	FaCampground,
	FaSuitcaseRolling,
	FaMusic,
	FaTh,
} from "react-icons/fa";

import { MdSportsSoccer, MdLiveTv, MdToys } from "react-icons/md";

import {
	MdOutlineFace,
	MdPhoneIphone,
	MdComputer,
	MdKitchen,
	MdMovie,
	MdPets,
} from "react-icons/md";
import { GiClothes, GiGrass } from "react-icons/gi";
import { SiNintendoswitch } from "react-icons/si";
import { RiHeartPulseFill, RiPencilRuler2Line } from "react-icons/ri";

// IconOptions array
const IconOptions = [
	{ name: "Baby & Toddler", icon: FaBabyCarriage },
	{ name: "Beauty", icon: MdOutlineFace },
	{ name: "Books", icon: FaBook },
	{ name: "Cameras", icon: FaCameraRetro },
	{ name: "Camping & Outdoors", icon: FaCampground },
	{ name: "Cellphones & Wearables", icon: MdPhoneIphone },
	{ name: "Computers & Tablets", icon: MdComputer },
	{ name: "Fashion", icon: GiClothes },
	{ name: "Gaming", icon: SiNintendoswitch },
	{ name: "Garden, Pool & Patio", icon: GiGrass },
	{ name: "Health", icon: RiHeartPulseFill },
	{ name: "Home & Kitchen", icon: MdKitchen },
	{ name: "Luggage & Travel", icon: FaSuitcaseRolling },
	{ name: "Movies & Series", icon: MdMovie },
	{ name: "Music", icon: FaMusic },
	{ name: "Office & Stationery", icon: RiPencilRuler2Line },
	{ name: "Pets", icon: MdPets },
	{ name: "Sport", icon: MdSportsSoccer },
	{ name: "TV, Audio & Video", icon: MdLiveTv },
	{ name: "Toys", icon: MdToys },
];

// IconByName component
const IconByName: React.FC<{ name: string } & React.ComponentProps<any>> = ({
	name,
	...props
}) => {
	const IconComponent = IconOptions.find(
		(option) => option.name === name
	)?.icon;

	// If an icon was found for the given name, render it with the passed props
	// Otherwise, return null or a default icon
	return IconComponent ? <IconComponent {...props} /> : null;
};

import { useNavigate } from "react-router-dom";
const useCategoriesData = () => {
	const { fetchMerchandisedDepartments } = useDepartmentsApi();

	const { data, isLoading, error } = useQuery({
		queryKey: [MERCHANDISED_DEPARTMENTS],
		queryFn: fetchMerchandisedDepartments,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		isLoading,
		error,
	};
};

interface Category {
	display_value: string;
	value: string;
	type: string;
	num_docs: number;
	parent_value: string | null;
	forest_value: string;
	is_checked: boolean;
	department_slug: string;
	category_slug: string;
	children: Category[];
	has_children: boolean;
}

interface Department {
	department_id: number;
	name: string;
	slug: string;
}

interface ApiResponse {
	department: Department;
	categories: Category[];
}

export default function Categories({ onClick, onBack }: any) {
	const navigate = useNavigate();
	const { data, isLoading, error }: any = useCategoriesData();
	const [selectedDepartment, setSelectedDepartment] = useState<any>(null);

	function getCategoriesByDepartmentId(
		data: ApiResponse[],
		departmentId: number
	): Category[] {
		// Initialize an empty array for the categories
		let categories: Category[] = [];

		if (!departmentId) return [];

		// Loop through the data array
		for (const item of data) {
			// Check if the current item's department ID matches the one we're looking for
			if (item.department.department_id === departmentId) {
				// If it matches, concatenate the current item's categories with the result array
				categories = categories.concat(item.categories);
				// Assuming you only have one department per department_id
				break;
			}
		}

		// Return the collected categories
		return categories;
	}

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	return (
		<div>
			{/* <pre>
				{JSON.stringify(getCategoriesByDepartmentId(data, 10), null, 2)}
			</pre> */}
			<div className="w-full">
				<div
					onClick={onBack}
					className="bg-[#171717] text-white flex gap-4 p-3 px-4 cursor-pointer lg:cursor-default text-md rounded-none lg:rounded-tl-lg lg:rounded-tr-lg"
				>
					<span className="lg:hidden">
						<IoArrowBack size={15} color="#7E7E7E" />
					</span>
					<span>Shop By Category</span>
				</div>
				{!selectedDepartment && (
					<div
						className="p-2 text-xs px-4 bg-white text-[#3D3D3D] flex justify-between items-center cursor-pointer border-b border-b-[#E4E4E4] group hover:text-primary"
						onClick={() => {
							navigate("/");
							if (onClick) onClick();
						}}
					>
						<div className="flex gap-4">
							<FaTh
								size={18}
								// color="#7E7E7E"
								className="#7E7E7E group-hover:text-primary"
							/>
							<span>All Categories</span>
						</div>

						<IoChevronForwardOutline size={20} color="#7E7E7E" />
					</div>
				)}
				{data && !selectedDepartment ? (
					data.map((cat: any, key: any) => (
						<div
							key={key}
							className="p-2 text-xs px-4 bg-white text-[#3D3D3D] flex justify-between items-center cursor-pointer border-b border-b-[#E4E4E4] group hover:text-primary"
							onClick={() => {
								scrollToTop();
								setSelectedDepartment(cat.department);
								// onClick();
							}}
						>
							<div className="flex gap-4">
								<IconByName
									name={cat.department.name}
									size={15}
									// color="#7E7E7E"
									className="#7E7E7E group-hover:text-primary"
								/>

								<span className="text-xs">
									{cat.department.name}
								</span>
							</div>
							<IoChevronForwardOutline
								size={15}
								// color="#7E7E7E"
								className="#7E7E7E group-hover:text-primary"
							/>
						</div>
					))
				) : (
					<>
						<div
							className="p-2 text-xs px-4 bg-white text-[#3D3D3D] flex gap-2 items-center cursor-pointer border-b border-b-[#E4E4E4] group hover:text-primary"
							onClick={() => {
								setSelectedDepartment(null);
								// onClick();
							}}
						>
							<IoArrowBack
								size={15}
								// color="#7E7E7E"
								className="#7E7E7E group-hover:text-primary"
							/>
							<span className="">Back</span>
						</div>
						<div
							className="p-2 text-xs px-4 bg-white text-[#3D3D3D] flex justify-between items-center cursor-pointer border-b border-b-[#E4E4E4] group hover:text-primary"
							onClick={() => {
								scrollToTop();
								navigate(
									`/categories/${selectedDepartment.slug}/all`
								);
								if (onClick) onClick();
							}}
						>
							<span>All {selectedDepartment?.name}</span>
							<IoChevronForwardOutline
								size={15}
								// color="#7E7E7E"
								className="#7E7E7E group-hover:text-primary"
							/>
						</div>
						{getCategoriesByDepartmentId(
							data,
							selectedDepartment?.department_id
						).map((cat, key) => (
							<div
								key={key}
								className="p-2 text-xs px-4 bg-white text-[#3D3D3D] flex justify-between items-center cursor-pointer border-b border-b-[#E4E4E4] group hover:text-primary"
								onClick={() => {
									scrollToTop();
									navigate(
										`/categories/${selectedDepartment.slug}/${cat.category_slug}`
									);
									if (onClick) onClick();
								}}
							>
								<span>{cat.display_value}</span>
								<IoChevronForwardOutline
									size={15}
									// color="#7E7E7E"
									className="#7E7E7E group-hover:text-primary"
								/>
							</div>
						))}
					</>
				)}

				<div
					className="hidden lg:flex gap-4 cursor-pointer items-center bg-[#e12a2d] hover:bg-black text-white text-center p-2 px-4 text-xl rounded-none lg:rounded-bl-lg lg:rounded-br-lg"
					onClick={() => {
						navigate("/");
						scrollToTop();
						if (onClick) onClick();
					}}
				>
					<IoTimeOutline /> <span>Daily Sale</span>
				</div>
			</div>
		</div>
	);
}
