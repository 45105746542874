import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/logo.png";
import { MdOutlineSupportAgent } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import { FaFacebookSquare, FaInstagramSquare, FaUser } from "react-icons/fa";
import useShoppingCart from "@/hooks/useShoppingCart";
import useGlobal from "@/hooks/useGlobal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TbTruckDelivery } from "react-icons/tb";
import { FiUser } from "react-icons/fi";

import "./style.css";

import { FloatingWhatsApp } from "@carlos8a/react-whatsapp-floating-button";

import payment_methods from "../../assets/payment-methods.png";
import BrandSlider from "../ui/brand-slider";
import { formatNumber, getCorridor } from "@/lib/utils";
import { Categories } from "../ui/sidebar";

import { IoHeart, IoHome, IoLogoWhatsapp } from "react-icons/io5";
import { MdManageSearch, MdOutlineAccessTime } from "react-icons/md";

import { version } from "../../../package.json";
import PostHogPageviewTracker from "../PostHogPageviewTracker";

export default function StoreLayout() {
	const navigate = useNavigate();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [shopByCategory, setShopByCategory] = useState(false);

	const { totalCartItems, totalCartPrice } = useShoppingCart();
	const { showCategories, toggleCategories } = useGlobal();

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	// Define your menu items once here.
	const menuItems = [
		{ title: "Daily Sale", url: "/" },
		{ title: "Tech Deals", url: "/categories/computers/all" },
		{ title: "Homeware Deals", url: "/categories/home-kitchen/all" },
	];

	const FooterContent = () => {
		return (
			<div className="sm:container flex justify-center sm:justify-between items-center">
				<span className="text-xs flex flex-col sm:flex-row items-center gap-1 sm:text-sm text-[#4d4d4f]">
					<span>
						Copyright &copy; 2024. Aviv Online Retail Group t/a The
						Daily Sale Shop {getCorridor().country}. All Rights
						Reserved.
					</span>
					<span className="text-gray-400">Version {version}</span>
				</span>
				<img
					src={logo}
					className="w-24 cursor-pointer hidden sm:block"
					onClick={() => navigate("/")}
				/>
				{/* <img src={payment_methods} className="w-36 lg:w-72" /> */}
				<div className="flex flex-row justify-end gap-2">
					{getCorridor().social.map((s, i) => (
						<div key={i}>
							{s.platform === "facebook" && (
								<span>
									<FaFacebookSquare
										className="cursor-pointer hover:text-[#1877f2]"
										size={35}
										onClick={() =>
											window.open(s.url, "_blank")
										}
									/>
								</span>
							)}
							{s.platform === "instagram" && (
								<span>
									<FaInstagramSquare
										className="cursor-pointer hover:text-[#833ab4]"
										size={35}
										onClick={() =>
											window.open(s.url, "_blank")
										}
									/>
								</span>
							)}
						</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<>
			<PostHogPageviewTracker />

			<div className=" bg-[#f4f4f4] flex flex-col min-h-screen">
				<div
					className={`bg-white w-full sticky top-0 ${isMobileMenuOpen ? "z-[999]" : "z-[9]"} p-1 lg:p-4 border-b border-b-[#E1E1E1]`}
				>
					<div className="flex grid-container justify-between items-center">
						<div className="flex items-center">
							<button
								className="ml-2 lg:hidden mr-2"
								onClick={() => {
									setIsMobileMenuOpen(!isMobileMenuOpen);
									if (showCategories) toggleCategories();
								}}
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M4 6h16M4 12h16m-7 6h7"
									></path>
								</svg>
							</button>
							<img
								src={logo}
								className="w-24 cursor-pointer"
								onClick={() => {
									if (isMobileMenuOpen) {
										setIsMobileMenuOpen(false);
									}
									navigate("/");
								}}
							/>
							<div className="hidden lg:flex gap-4 ml-4">
								{menuItems.map((item, key) => (
									<span
										className="cursor-pointer text-[#4d4d4f] hover:text-primary pr-4 border-r border-r-[rgba(183,183,184,0.4)] last:border-r-0"
										key={key}
										onClick={() => {
											scrollToTop();
											navigate(item.url);
										}}
									>
										{item.title}
									</span>
								))}
							</div>
						</div>
						{(isMobileMenuOpen || showCategories) && (
							<div className="lg:hidden absolute flex flex-col top-full left-0 right-0 bg-white shadow-md h-screen border-t border-t-[#E2E2E2] overflow-y-auto mb-8">
								{shopByCategory || showCategories ? (
									<div className=" mb-14">
										<Categories
											onClick={() => {
												setIsMobileMenuOpen(false);
												setShopByCategory(false);
												toggleCategories();
											}}
											onBack={() => {
												setShopByCategory(false);
												toggleCategories();
											}}
										/>
									</div>
								) : (
									<div className="h-full flex flex-col justify-between mb-[53px]">
										<div>
											<div
												className="block py-2 cursor-pointer px-4 text-sm hover:bg-primary hover:text-white"
												onClick={() => {
													navigate("/");
													setIsMobileMenuOpen(false);
												}}
											>
												Home
											</div>
											<div
												className="block py-2 cursor-pointer px-4 text-sm hover:bg-primary hover:text-white"
												onClick={() => {
													setShopByCategory(true);
													toggleCategories();
												}}
											>
												Shop by Category
											</div>
											{menuItems.map((item, key) => (
												<div
													className="block py-2 cursor-pointer px-4 text-sm hover:bg-primary hover:text-white"
													key={key}
													onClick={() => {
														setIsMobileMenuOpen(
															false
														);
														scrollToTop();
														navigate(item.url);
													}}
												>
													{item.title}
												</div>
											))}
											<hr />
											{[
												{
													title: "About Us",
													url: "/about-us",
												},
												{
													title: "Privacy Policy",
													url: "/privacy-policy",
												},
												{
													title: "Terms & Conditions",
													url: "/terms-and-conditions",
												},
												{
													title: "Payment Policy",
													url: "/payment-policy",
												},
												{
													title: "Warranty Policy",
													url: "/warranty-policy",
												},
												{
													title: "Returns & Refund Policy",
													url: "/refund-policy",
												},
											].map((item, key) => (
												<div
													className="block py-2 cursor-pointer px-4 text-sm hover:bg-primary hover:text-white"
													key={key}
													onClick={() => {
														setIsMobileMenuOpen(
															false
														);
														scrollToTop();
														navigate(item.url);
													}}
												>
													{item.title}
												</div>
											))}
											<hr />
											{[
												{
													title: "Contact Us",
													url: "/contact-us",
												},
												// {
												// 	title: "Track Order",
												// 	url: "/tracking",
												// },
												{
													title: "Wishlist",
													url: "/wishlist",
												},
												{
													title: "Help Center",
													url: "/help",
												},
											].map((item, key) => (
												<div
													className="block py-2 cursor-pointer px-4 text-sm hover:bg-primary hover:text-white"
													key={key}
													onClick={() => {
														setIsMobileMenuOpen(
															false
														);
														scrollToTop();
														navigate(item.url);
													}}
												>
													{item.title}
												</div>
											))}
										</div>
										<div className=" h-14">
											<FooterContent />
										</div>
									</div>
								)}
							</div>
						)}
						<div className="flex flex-row items-center gap-2">
							<span
								onClick={() =>
									// window.location.replace(
									// 	`https://api.whatsapp.com/send/?phone=${getCorridor().whatsapp}&text=Hello`
									// )
									navigate("/help")
								}
								className="flex items-center lg:hidden cursor-pointer text-[#fff] p-2 bg-[#25d366] hover:bg-[#075e54] text-sm rounded-full"
								title="Contact Us on WhatsApp"
							>
								<IoLogoWhatsapp size={25} />
							</span>
							<span
								onClick={() => navigate("/wishlist")}
								className="flex items-center lg:hidden group cursor-pointer text-[#4d4d4f] pt-2.5 px-2.5 bg-[#e12a2d] hover:bg-black p-2 text-sm rounded-full"
								title="Wish List"
							>
								<IoHeart
									size={25}
									className="text-white group-hover:text-red-500 duration-500"
								/>
							</span>
							{!window.location.href.includes("/checkout") && (
								<span
									className="flex gap-2 my-1 items-center lg:hidden cursor-pointer text-[#4d4d4f] bg-[#e12a2d] py-1 hover:bg-black p- px-3 text-sm rounded-md sm:rounded-lg"
									onClick={() => navigate("/cart")}
								>
									<IoMdCart size={20} color="white" />
									{totalCartItems() > 0 && (
										<div className="relative">
											<span className="absolute -top-5 -right-1 bg-black rounded-full h-5 w-5 flex justify-center items-center text-white text-[10px]">
												{totalCartItems()}
											</span>
										</div>
									)}
									<div className="flex flex-col">
										<span className="text-xs text-black group-hover:text-white">
											Your Order:
										</span>
										<span className="text-white">
											{getCorridor().currency}{" "}
											{formatNumber(totalCartPrice())}
										</span>
									</div>
								</span>
							)}
						</div>
						<div className="hidden lg:flex gap-4 items-center">
							<span
								onClick={() => navigate("/help")}
								className="flex gap-2 items-center p-2 px-3 text-[#4d4d4f] cursor-pointer hover:bg-gray-300 text-sm rounded-lg"
							>
								<MdOutlineSupportAgent size={20} />
								<span>Help Center</span>
							</span>
							{/* <span
								onClick={() => navigate("/tracking")}
								className="flex gap-2 items-center p-2 px-3 text-[#4d4d4f] cursor-pointer hover:bg-gray-300 text-sm rounded-lg"
							>
								<TbTruckDelivery size={20} />
								<span>Track My Order</span>
							</span> */}
							{/* <span
								onClick={() => navigate("/account")}
								className="flex gap-2 items-center p-2 px-3 text-[#4d4d4f] cursor-pointer hover:bg-gray-300 text-sm rounded-lg"
							>
								<FiUser size={20} />
								<span>My Account</span>
							</span> */}

							<span
								onClick={() => navigate("/wishlist")}
								className="flex items-center group cursor-pointer text-[#4d4d4f] pt-2.5 px-2.5 bg-[#e12a2d] hover:bg-black p-2 text-sm rounded-full"
								title="Wish List"
							>
								<IoHeart
									size={25}
									className="text-white group-hover:text-red-500 duration-500"
								/>
							</span>
							{!window.location.href.includes("/checkout") && (
								<span
									className="flex gap-2 items-center group cursor-pointer text-[#4d4d4f] bg-[#e12a2d] py-1 hover:bg-black p-2 px-3 text-sm rounded-md sm:rounded-lg"
									onClick={() => navigate("/cart")}
								>
									<IoMdCart
										size={20}
										className="text-white"
									/>
									{totalCartItems() > 0 && (
										<div className="relative">
											<span className="absolute -top-5 -right-1 bg-black group-hover:bg-white group-hover:text-black rounded-full h-5 w-5 flex justify-center items-center text-white text-[10px]">
												{totalCartItems()}
											</span>
										</div>
									)}
									<div className="flex flex-col">
										<span className="text-xs text-black group-hover:text-white">
											Your Order:
										</span>
										<span className="text-white">
											{getCorridor().currency}{" "}
											{formatNumber(totalCartPrice())}
										</span>
									</div>
								</span>
							)}
						</div>
					</div>
				</div>

				{/* Body Starts Here */}
				<div className="flex-grow w-full">
					<Outlet />
					<FloatingWhatsApp
						phoneNumber={getCorridor().whatsapp} // Required
						accountName="Customer Support" // Optional
						avatar="/favicon.png" // Optional
						initialMessageByServer="Hi there! How can I assist you today?" // Optional
						statusMessage="Available" // Optional
						// placeholder="Write here..." // Optional
						allowEsc={true}
						allowClickAway={true}
						// Explore all available props below
						className="hidden lg:block"
						chatboxClassName="mb-8"
						buttonClassName="mb-8"
					/>
				</div>
				{/* Footer Starts Here */}
				<div className=" bg-white hidden sm:block">
					<div className="grid-container py-4 w-full relative">
						<BrandSlider />
					</div>
				</div>
				<div className="bg-white border-t border-t-[#E1E1E1] px-2 sm:px-12 w-full py-8 hidden sm:block">
					<div className="flex text-xs justify-start flex-col sm:flex-row sm:justify-between gap-4  sm:text-sm grid-container">
						<ul className="flex  flex-col gap-2">
							<li className="text-[#e12a2d] font-bold">
								Get to Know Us
							</li>
							<Link
								to="/"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Search Products
							</Link>
							<Link
								to="/about-us"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								About Us
							</Link>
							<Link
								to="/help"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Help Center
							</Link>
						</ul>
						<ul className="flex  flex-col gap-2">
							<li className="text-[#e12a2d] font-bold">Legal</li>

							<Link
								to="/privacy-policy"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Privacy Policy
							</Link>
							<Link
								to="/terms-and-conditions"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Terms & Conditions
							</Link>
						</ul>
						<ul className="flex  flex-col gap-2">
							<li className="text-[#e12a2d] font-bold">
								Orders & Refunds
							</li>
							<Link
								to="/payment-policy"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Payment Policy
							</Link>
							<Link
								to="/warranty-policy"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Warranty Policy
							</Link>
							<Link
								to="/refund-policy"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Returns & Refund Policy
							</Link>
						</ul>
						<ul className="flex  flex-col gap-2">
							<li className="text-[#e12a2d] font-bold">Other</li>
							<Link
								to="contact-us"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Contact Us
							</Link>
							{/* <Link
								to="/tracking"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Track Order
							</Link> */}
							<Link
								to="/wishlist"
								className="text-[#4d4d4f] hover:text-black cursor-pointer"
							>
								Wishlist
							</Link>
						</ul>
					</div>
				</div>

				{/* Mobile */}
				<div className="bg-[#e12a2d] w-full py-2 sticky bottom-0 sm:hidden text-xs flex justify-between items-center text-white px-4">
					<div
						onClick={() => navigate("/")}
						className="flex flex-col justify-center items-center gap-1 cursor-pointer"
					>
						<IoHome size={15} />
						<span>Home</span>
					</div>
					<div
						onClick={() => toggleCategories()}
						className="flex flex-col justify-center items-center gap-1 cursor-pointer"
					>
						<MdManageSearch size={15} />
						<span>Shop By Category</span>
					</div>
					<div
						onClick={() => navigate("/")}
						className="flex flex-col justify-center items-center gap-1 cursor-pointer"
					>
						<MdOutlineAccessTime size={15} />
						<span>Daily Sale</span>
					</div>
					{/* <div
						onClick={() => navigate("/account")}
						className="flex flex-col justify-center items-center gap-1 cursor-pointer"
					>
						<FaUser size={15} />
						<span>Account</span>
					</div> */}
				</div>
				<footer className=" bg-white py-4 border-t border-t-[#E1E1E1]">
					<FooterContent />
				</footer>
			</div>
		</>
	);
}
