import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import posthog from "posthog-js";

const PostHogPageviewTracker = () => {
	const location = useLocation();

	useEffect(() => {
		posthog.capture("$pageview", { path: location.pathname });
	}, [location]);

	return null; // This component does not render anything
};

export default PostHogPageviewTracker;
